@use 'variables';

body {
  font-family: "Roboto", sans-serif;
  background: variables.$light-blue url("../../public/images/tictactoeboard.png") round 1px 0;
  margin: 0;
  min-height: 100vh;

  #root {
    & div:first-child {
      & div {
        background-color: variables.$pale-orange;
      }
      & button {
        background-color: variables.$purple;
        border-color: variables.$pale-orange;
      }
    }
  }
}

/* Scrollbar */
/* Width */
*::-webkit-scrollbar {
	background: variables.$light-blue;
	width: 15px;
}
  
/* Track */
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 10px #ffffff; 
	border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
	background: variables.$purple; 
	border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
	background: variables.$purple; 
}

