@use "variables";

#game_board {
  & div:first-child {
    & .MuiGrid-item {
      & div {
        border: 4px solid variables.$blue;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        &:hover {
          cursor: pointer;
        }
        
        & span {
          font-family: "playmegames";
          font-size: calc(1.01vw + 46.22px);
        }

        .text_shadows {
          animation: shadow 2s ease forwards infinite;
          animation-delay: .5s;
        }

        @keyframes shadow {
          0%, 100% {
            color: variables.$red;
            text-shadow: 0 0 0 #000;
          }
          50% {
            color: variables.$red;
            text-shadow: 0 30px 25px #000;
          }
        }
      }
    }
  }
  
  & div:first-child {
    & div:nth-child(1) > div {
      border-top: none;
      border-left: none;
    }

    & div:nth-child(2) > div {
      border-top: none;
    }

    & div:nth-child(3) > div {
      border-top: none;
      border-right: none;
    }

    & div:nth-child(4) > div {
      border-left: none;
    }

    & div:nth-child(6) > div {
      border-right: none;
    }

    & div:nth-child(7) > div {
      border-left: none;
      border-bottom: none;
    }

    & div:nth-child(8) > div {
      border-bottom: none;
    }

    & div:nth-child(9) > div {
      border-right: none;
      border-bottom: none;
    }
  }
}
